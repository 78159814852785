import React, { useEffect, useState } from 'react'
import Heading from '../../components/SectionHeading/Heading'
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader'
import axios from 'axios';
import { apiEndPoints } from '../../constants/ApiEndPoints';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { resetInactiveTimer } from '../../reduxStore/actions';

function AuditLogPageComponent() {
    const [isApiLoaded, setIsApiLoaded] = useState(false);
 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const pageDataLength = 10;
    const [desirePageData, setDesirePageData] = useState({
      PageNumber: "1",
      PageSize: pageDataLength,
    });
    let dispatch = useDispatch();
    let initialInactive = useSelector((state)=>state.resetInactivityTimer)
    const resetTimer = () => {
  
      dispatch(resetInactiveTimer({payload:initialInactive+1}))
    }
    const [dataSrc, setDataSrc] = useState({});
  
    useEffect(()=>{
        // get the audit logs
        setIsApiLoaded(true)
        let getTheAuditLog = JSON.stringify(desirePageData);
    
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: apiEndPoints.FETCH_AUDIT_LOGS,
          headers: {
            "X-Tenant": "Chiron",
            "Content-Type": "application/json",
            Authorization: "••••••",
          },
          data: getTheAuditLog,
        };
        resetTimer()
        axios.request(config)
          .then((response) => {
            setDataSrc(response.data);
            if(response?.data?.totalRecordCount > 0){
              let pages = response?.data?.totalRecordCount / 10;
          
              if (Number.isInteger(pages)) {
                setTotalPages(parseInt(pages));
              } else {
                setTotalPages(parseInt(pages) + 1);
              }
        
          }
          })
          .catch((error) => {
            console.log(error);
          }).finally(()=>{
            setIsApiLoaded(false)
          });
      },[desirePageData])


      const columns = [
        // {
        //   title: "Correlation Id",
        //   dataIndex: "correlationId",
        //   width: "33%",
        //   ellipsis: true,
        // },
    
        {
          title: "User",
          dataIndex: "userName",
          width: "33%",
          ellipsis: true,
        },
        {
          title: "Edge Name",
          dataIndex: "entity",
          width: "33%",
          ellipsis: true,
        },
        {
          title: "Edge Id",
          dataIndex: "entityId",
          width: "33%",
          ellipsis: true,
        },
        {
          title: "Event",
          dataIndex: "eventType",
          width: "33%",
          ellipsis: true,
        },
        {
          title: "Action",
          dataIndex: "event",
          width: "33%",
          ellipsis: true,
        },
        {
          title: "Time",
          dataIndex: "timestampUTC",
          width: "33%",
          ellipsis: true,
        },
      ];
    
      const changePage = (toPage) =>{
        setDesirePageData({
          PageNumber:toPage,
          PageSize: pageDataLength,
        })
         setCurrentPage(toPage);
      }
    
    
    
    
      const renderPageNumbers = () => {
        let pages = [];
    
        for (let i = 1; i <= totalPages; i++) {
          if (
            i === 1 || // Always show the first page
            i === totalPages || // Always show the last page
            i === currentPage || // Show the current page
            (i >= currentPage - 1 && i <= currentPage + 1) // Show pages around the current page
          ) {
            pages.push(
              <span
                key={i}
                onClick={() => changePage(i)}
                style={{
                  cursor: "pointer",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                  margin: "0 5px",
                  backgroundColor: currentPage === i ? "#000" : "transparent",
                  color: currentPage === i ? "#fff" : "#000",
                }}
              >
                {i}
              </span>
            );
          } else if (
            i === currentPage - 2 || // Add ellipsis before the current page
            i === currentPage + 2 // Add ellipsis after the current page
          ) {
            pages.push(
              <span key={i} style={{ margin: "0 5px" }}>
                ...
              </span>
            );
          }
        }
        console.log(pages)
        return pages;
      };

  return (
    <div className="App">
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}
      >
        <style
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <Heading text="Audit Log" />
          {isApiLoaded && <SpinnerLoader />}
        </style>
        {
          // render_AddAppBtn()
        }
      </div>
      <div className="tableBox">
               <div
          className="ViewContainer"
          id="ViewContainer"
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "5px",
            height: "calc(90vh - 17vh)",
          }}
        >
          <div style={{ flex: "1", backgroundColor: "#fff", height: "auto" }}>
        
            <>
              <Table
                columns={columns}
                dataSource={dataSrc.logs}
                pagination={false}
                size="medium"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  gap:'10px',
                  alignItems:'end'
                }}
              >
                { renderPageNumbers()}
               
              </div>
            </>
          
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuditLogPageComponent