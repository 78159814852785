const initialState = 0;

const resetInactivityTimer = (state= initialState, action) =>{
    switch (action.type) {
        case "RESET_INACTIVITY_TIMER":
            return state = action.payload
        default:
            return state;
    }
}

export default resetInactivityTimer;