import { Button, Form, Input, message, Modal, Space } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { commonAuthHelper } from "../../App";
import ReactJson from "react-json-view";
import { useDispatch, useSelector } from "react-redux";
import { resetInactiveTimer } from "../../reduxStore/actions";

function Widget({ data }) {
  const [form] = Form.useForm();

  const [loadingState, setLoadingState] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [image, setImage] = useState({});

  const handleImageChange = (event, fieldname) => {
    setImage((prev) => ({ ...prev, [fieldname]: event.target.files[0] }));
  };
  let dispatch = useDispatch();
  let initialInactive = useSelector((state)=>state.resetInactivityTimer)
  const resetTimer = () => {

    dispatch(resetInactiveTimer({payload:initialInactive+1}))
  }
  useEffect(() => {
    console.log(image);
  }, [image]);
  let Method_data = data;

  const showMethodModal = () => {
    setShowModal(true);
    // setTimeout(() => {
    //   setShowModal(false);
    // }, 5000);
  };

  useEffect(() => {
    console.log(loadingState);
  }, [loadingState]);

  const invokeMethod = async (
    methodObjectId,
    fieldValId,
    methodName,
    isnull,
    fields
  ) => {
    // console.log("dattasaa-----",fieldValId, methodName, isnull, fields);
    setLoadingState((prev) => ({ ...prev, [`btn_${fieldValId}`]: true }));
    let data_formData = new FormData();

    if (isnull === true) {
      data_formData.append(`arguments`, `{}`);
    } else {
      let fieldsKeys = Object.keys(fields);
      let values = {};
      fieldsKeys.map((key, index) => {
        let fieldValue = document.getElementById(`${key}_${methodName}`).value;

        if (fields[key].dataType == "integer") {
          fieldValue = parseInt(fieldValue);
        }
        
        if (fields[key].dataType == "boolean") {
          fieldValue = Boolean(fieldValue);
        }

        if (fields[key].dataType != "file") {
          values[key] = fieldValue;
        }

        if (fields[key].dataType == "file") {
          const imageBlob = new Blob([image[`${key}_${methodName}`]], {
            type: "application/octet-stream",
          });
          data_formData.append(
            key,
            imageBlob,
            image[`${key}_${methodName}`].name
          );
        }
      });
      if (Object.keys(values).length > 0) {
        data_formData.append(`arguments`, `${JSON.stringify(values)}`);
      }
    }

    try {
      const token = commonAuthHelper.getAccessToken();
      const tenant = localStorage.getItem("tenant");

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: apiEndPoints.INVOKE_METHOD(methodObjectId, methodName),
        data: data_formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenant,
          "Content-Type": "multipart/form-data",
        },
      };
      resetTimer()
      let temp = axios.create();

      temp
        .request(config)
        .then((response) => {
          console.log(response.data);
          // let payloaddd = JSON.parse(response.data.payload)
          // response.data['payload'] = payloaddd
          setMessage(response.data);
        })
        .catch((error) => {
          setMessage("Error occured");
          console.log(error);
        })
        .finally(() => {
          showMethodModal();
          setLoadingState((prev) => ({
            ...prev,
            [`btn_${fieldValId}`]: false,
          }));
        });
    } catch (error) {
      console.error("Error uploading data:", error);
      message.error("Method Update Failed");
    } finally {
    }
  };

  const onFinish = (values) => {
    console.log(values);
  };

  const retuntheInputs = (methodObjectId, method) => {
    // if (method == null) return;

    const { input: inputData, name } = method;

    if (inputData == null) {
      return (
        <Form.Item>
          <Button
            style={{
              marginTop: "10px",
              borderRadius: "100px",
              color: "#fff",
              background: "#000",
            }}
            loading={loadingState[`btn_${name}`] || false}
            onClick={() => invokeMethod(methodObjectId, `${name}`, name, true)}
          >
            {method.name}
          </Button>
        </Form.Item>
      );
    } else {
      const keys = Object.keys(inputData);
      return keys.map((field, key) => (
        <>
          <Form.Item
            label={field}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {(inputData[field]?.dataType === "string" ||
              inputData[field]?.dataType === "integer" ||
              inputData[field]?.dataType === "text") && (
              <Input id={`${field}_${name}`} />
            )}

            {inputData[field]?.dataType == "file" && (
              <Input
                onChange={(e) => handleImageChange(e, `${field}_${name}`)}
                type="file"
                id={`${field}_${name}`}
              />
            )}

            {inputData[field]?.dataType == "boolean" && (
              <select id={`${field}_${name}`}>
                <option>true</option>
                <option>false</option>
              </select>
            )}

            {keys.length - 1 == key && (
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    marginTop: "10px",
                    borderRadius: "100px",
                    color: "#fff",
                    background: "#000",
                  }}
                  loading={loadingState[`btn_${field}_${name}`] || false}
                  onClick={() =>
                    invokeMethod(
                      methodObjectId,
                      `${field}_${name}`,
                      name,
                      false,
                      inputData
                    )
                  }
                >
                  {name}
                </Button>
              </div>
            )}
          </Form.Item>
        </>
      ));
    }
  };
  const renderMethods = () => {
    return Method_data.map((method, index) => (
      <div>
        <div style={{ fontSize: "18px" }}>
          {Method_data[index]?.description}
        </div>
        {method.methods.map((methods_method, key) => (
          <>
            <span style={{ fontSize: "15px" }}>
              {methods_method?.description}
            </span>
            {retuntheInputs(Method_data[index]?.objectId, methods_method)}
          </>
        ))}
      </div>
    ));
  };
  return (
    <div>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        {renderMethods()}
      </Form>
      <Modal
        centered
        open={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        width="50%"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "50px",
            height:'auto',
            fontFamily: "ABBVoice",
            overflow:'auto'
          }}
        >
          <ReactJson collapseStringsAfterLength={100} style={{width:'100%', marginTop:'25px', border:'none'}} displayDataTypes = {false} name={false}  enableClipboard = {true} src={message} />
          {/* <textarea rows="20" style={{width:'100%', marginTop:'25px', border:'none'}} contentEditable={false} value= {
            // message?.result
            // ? message.result
            // : message?.error
            // ? message.error
            // : 
            message}/> */}
         
        </div>
      </Modal>
    </div>
  );
}

export default Widget;
