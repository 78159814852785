import React, { useState, useEffect } from "react";
import EdgeSettings from "../components/EdgeSettings/EdgeSettings";
import SpinnerLoader from "../components/SpinnerLoader/SpinnerLoader";
import AuthenticationSettings from "../components/AuthenticationSettings/AuthenticationSettings";
import BrokerSettings from "../components/BrokerSettings/BrokerSettings";

import axios from "axios";
import { apiEndPoints } from "../constants/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import {
  resetInactiveTimer,
  setAuthenticationSettings,
  setBasicEdgeSettings,
  setBrokerEdgeSettings,
  setEdgeAdminSettings,
  setRouterEdgeSettings,
  setRoutesModels,
} from "../reduxStore/actions";
import RouterSettings from "../components/RouterSettings/RouterSettings";
import Heading from "../components/SectionHeading/Heading";
import EdgeAdminSettings from "../components/EdgeAdminSettings/EdgeAdminSettings";
import NoDataComponent from "../components/NoDataComponent/NoDataComponent";
import { message } from "antd";
import { General_color } from "../constants/GeneralConstants";

function EdgeSettingsPage() {
  const [options, setAlloptions] = useState([]);

  const [selectedView, setselectedView] = useState("");

  const [isSettingsDataFetched, setIsSettingsDataFetched] = useState(false);
  const [showLeftPane, toggleLeftPane] = useState(true);
  // const access_token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  // const { edgeId, platformType } = useParams();
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const platformType = useSelector(
    (state) => state.setEdgeDetails?.data?.[4]?.detail
  );

  let initialInactive = useSelector((state)=>state.resetInactivityTimer)
  const resetTimer = () => {

    dispatch(resetInactiveTimer({payload:initialInactive+1}))
  }
  let isUnsavedEdgeSettings = useSelector(
    (state) => state.isUnsavedEdgeSettings
  );
  // const checkSe ttingsFetched = useSelector(state=>state.setBasicEdgeSettings)
  const isEdgeSettingVisible = useSelector((state) => state.showEdgeSettings);
  const tempRoutes = useSelector((state) => state.setTempRoutes);
  useEffect(() => {
    // fetch Router Modules

    if (isEdgeSettingVisible == false) {
      return;
    }
    setIsSettingsDataFetched(false);
    let fetchRouterModuless = apiEndPoints.FETCH_ROUTER_MODULES.replace(
      "*edgeId*",
      edgeId
    );
    let router_config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchRouterModuless,
   
    };
    resetTimer()
    axios
      .request(router_config)
      .then((response) => {
        dispatch(setRoutesModels({ payload: response.data }));
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch all settings for edge
    let fetchBaisicSettings = apiEndPoints.FETCH_BASIC_EDGE_SETTINGS.replace(
      "*edgeId*",
      // "f9e0fdc4-a3c6-4a79-af70-bed6f4f45b5e"
      edgeId
    );
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchBaisicSettings,
    
    };
    resetTimer()
    axios
      .request(config)
      .then((response) => {
        setIsSettingsDataFetched(true);
        let settingsdata = response.data;
        let sections = [];
        settingsdata.forEach((setting) => {
          sections.push(setting.sectionName);
        });
        let tempReorder = sections[3];
        sections.splice(3, 1);
        sections.splice(1, 0, tempReorder);
        setAlloptions(sections);
        console.log(sections);
        setselectedView(settingsdata[0].sectionName);

        dispatch(
          setBasicEdgeSettings({
            payload:
              settingsdata.filter(
                (set) => set.sectionName === "Basic Edge Settings"
              ).length > 0
                ? settingsdata.filter(
                    (set) => set.sectionName === "Basic Edge Settings"
                  )[0]
                : {},
          })
        );
        dispatch(
          setBrokerEdgeSettings({
            payload:
              settingsdata.filter(
                (set) => set.sectionName === "Broker Settings"
              ).length > 0
                ? settingsdata.filter(
                    (set) => set.sectionName === "Broker Settings"
                  )[0]
                : {},
          })
        );
        dispatch(
          setRouterEdgeSettings({
            payload:
              settingsdata.filter((set) => set.sectionName === "Routing")
                .length > 0
                ? settingsdata.filter((set) => set.sectionName === "Routing")[0]
                : {},
          })
        );
        dispatch(
          setAuthenticationSettings({
            payload:
              settingsdata.filter(
                (set) => set.sectionName === "Authentication Settings"
              ).length > 0
                ? settingsdata.filter(
                    (set) => set.sectionName === "Authentication Settings"
                  )[0]
                : {},
          })
        );

        dispatch(
          setEdgeAdminSettings({
            payload:
              settingsdata.filter(
                (set) => set.sectionName === "Edge Admin Registration"
              ).length > 0
                ? settingsdata.filter(
                    (set) => set.sectionName === "Edge Admin Registration"
                  )[0]
                : {},
          })
        );
        // dispatch(setRouterEdgeSettings({ payload:settingsdata.filter(set=>set.sectionName==='Authentication Settings')[0] }))
      })
      .catch((error) => {
        console.log(error);
      });
  }, [isEdgeSettingVisible]);

  const renderView = () => {
    // eslint-disable-next-line default-case
    switch (selectedView) {
      case "Basic Edge Settings": {
        return <EdgeSettings />;
      }
      case "Routing": {
        return <RouterSettings />;
      }
      case "Authentication Settings": {
        return <AuthenticationSettings />;
      }
      case "Broker Settings": {
        return <BrokerSettings />;
      }
      case "Edge Admin Registration": {
        return <EdgeAdminSettings />;
      }
    }
  };

  const changeView = (view) => {
    if (isUnsavedEdgeSettings) {
      message.info(
        ` ${selectedView} unsaved, please apply changes or revert back`
      );
    } else {
      setselectedView(view);
    }
    // if(selectedView!="Routing"){
    //   if (tempRoutes?.properties?.[0]?.value?.routes != undefined) {
    //     let checkKeys = Object.keys(tempRoutes?.properties?.[0]?.value?.routes);
    //     if (checkKeys.length > 0) {
    //       message.info("Routing settings unsaved, please save or revert")
    //       setselectedView("Routing")
    //       return
    //     }
    //   }
    // }
  };
  return (
    <section className="App">
      <Heading text="Settings" />
      {isSettingsDataFetched ? (
        <div>
          {platformType !== "connectondemand" && (
            <span>
              Please configure basic settings to initialize the system for first
              use.
            </span>
          )}

          <div className="Parent_settings_container">
            <div
              className="menu-view"
              style={{
                width: !showLeftPane ? "20px" : "",
              }}
            >
              <div
                className="app_name"
                style={{
                  display: !showLeftPane ? "none" : "block",
                }}
              ></div>
              <div className="Alloptions">
                {options?.length > 0 &&
                  options?.map((option, index) => (
                    <span
                      onClick={() =>
                        platformType == "connectondemand" && changeView(option)
                      }
                      className="heading_2"
                      style={{
                        // background: selectedView == option ? "#c9c9c9" : "",
                        borderBottomColor:
                          selectedView == option ? "#666262" : "",
                        // color:index!==0&&"#bfbfbf"
                        color:
                          platformType == "connectondemand"
                            ? "#000"
                            : General_color.disable,
                      }}
                    >
                      {showLeftPane && option === "Basic Edge Settings"
                        ? option.replace("Edge", "")
                        : option.replace("Settings", "").replace("Edge", "")}
                    </span>
                  ))}
              </div>
            </div>
            <div className="setings_container">
              {/* <CommonLoader isLoading={edgesState.isLoading} /> */}
              <div className="settings_row">
                {/* <AppReleaseNote releaseHistory={appInfo.releaseHistory} /> */}
                {renderView()}

                {isSettingsDataFetched && options.length == 0 && (
                  <NoDataComponent message="No settings at the moment" />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <span style={{ marginLeft: "10px" }}>
          <SpinnerLoader />{" "}
        </span>
      )}
    </section>
  );
}

export default EdgeSettingsPage;
