import Axios from "axios";
import React, { useEffect, useState } from "react";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CreateGuid, generateTime } from "../../constants/commonFunction";
import {
  isUnsavedEdgeSettings,
  resetInactiveTimer,
  setPageNotifications,
} from "../../reduxStore/actions";
import {
  GeneralConstants,
  General_color,

} from "../../constants/GeneralConstants";
import TempComponent from "../TempComponent";

function Widget() {
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const dispatch = useDispatch();
  let detailsData = useSelector((state) => state.setEdgeDetails);
  const [isSavingEdgeAdministrator, setIsSavingEdgeAdministrator] =
    useState(false);
  const [ischanged, setIsChanged] = useState(false);
  const [providerIdValue, setProviderIdValue] = useState("");
  const [usePrincipalValue, setusePrincipalValue] = useState("");
  const [nameValue, setnameValue] = useState("");
  const [resetThis, setResetThis] = useState(0);
  const [sudoState, setSudoState] = useState(0);
  let initialInactive = useSelector((state)=>state.resetInactivityTimer)
  const resetTimer = () => {

    dispatch(resetInactiveTimer({payload:initialInactive+1}))
  }
  const edgeAdminSettingsData = useSelector(
    (state) => state.setEdgeAdminSettings
  );
  const targets = [
    "edgeauthadminapi.registerIdentityProvider",
    "edgeauthadminapi.registerEdgeAdministrator",
  ];

  let payload = [
    {
      sectionName: edgeAdminSettingsData.sectionName,
      parentTypeId: edgeAdminSettingsData.parentTypeId,
      properties: [
        {
          value: {},
          targets: "",
        },
      ],
    },
  ];

  useEffect(() => {
    setProviderIdValue(
      edgeAdminSettingsData?.properties?.[1]?.value == null
        ? ""
        : edgeAdminSettingsData?.properties?.[1]?.value
    );
    setusePrincipalValue(
      edgeAdminSettingsData?.properties?.[2]?.value == null
        ? ""
        : edgeAdminSettingsData?.properties?.[2]?.value
    );
    setnameValue(
      edgeAdminSettingsData?.properties?.[0]?.value == null
        ? ""
        : edgeAdminSettingsData?.properties?.[0]?.value
    );

    setIsChanged(false);
  }, [edgeAdminSettingsData, resetThis]);

  useEffect(() => {
    if (
      providerIdValue == edgeAdminSettingsData?.properties?.[1]?.value &&
      usePrincipalValue == edgeAdminSettingsData?.properties?.[2]?.value &&
      nameValue == edgeAdminSettingsData?.properties?.[0]?.value
    ) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  }, [sudoState, providerIdValue, usePrincipalValue, nameValue]);

  let notiMsg;
  const registerEdgeAdministrator = (values) => {
    values.ProviderId = providerIdValue;
    values.UserPrincipalName = usePrincipalValue;
    values.Name = nameValue;
    payload[0].properties[0].value = values;
    payload[0].properties[0].targets = targets[1];
    calltheApi(payload, 1);
  };

  let oldNotifications = useSelector((state) => state.setPageNotifications);

  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
    let previosData = [];
    if (oldNotifications.newData.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };

  const calltheApi = (nativePayload, buttonNumber) => {
    setIsSavingEdgeAdministrator(true);

    let data = JSON.stringify(nativePayload);

    let updateAuthSettingsURL = apiEndPoints.SET_ALL_EDGE_SETTINGS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: updateAuthSettingsURL,
      
      data: data,
    };
    resetTimer()
    Axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));

        setIsSavingEdgeAdministrator(false);

        setIsChanged(false);
        data = JSON.parse(data)[0];

        AddEdgeSettingsNotification(
          `${data.sectionName} applied successfully`,
          `edgeSettingUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: true,
            modalKey: `edgeSettingUpdate_${CreateGuid()}`,
            setting_type: data.parentTypeId.split("/")[1],
            settingSectionName: data.sectionName,
            edgeName: localStorage.getItem("edgeName"),
            appName: "",
            successMessage: `${data.sectionName} applied successfully`,
            time: generateTime(),
            isComplete: false,
            limit: 3,
            currStateValue: 0,
            icon: "good",
          }
        );
      })
      .catch((error) => {
        console.log(error);
        setIsChanged(false);
        setIsSavingEdgeAdministrator(false);
        notiMsg = "Registering edge administrator failed, please try again.";
        AddEdgeSettingsNotification(
          `${notiMsg}`,
          `EdgeRelatedUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: true,
            modalKey: `EdgeRelatedUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            appName: "",
            successMessage: `${notiMsg}`,
            time: generateTime(),
            isComplete: true,
            limit: 3,
            currStateValue: 0,
            icon: "failed",
          }
        );
        message.error(notiMsg);
      });
  };

  const trueFalse = () => {
    if (
      ischanged &&
      `${providerIdValue}` &&
      `${usePrincipalValue}` &&
      `${nameValue}`
    ) {
      dispatch(isUnsavedEdgeSettings({ payload: true }));
      return false;
    } else {
      dispatch(isUnsavedEdgeSettings({ payload: false }));
      return true;
    }
  };

  return (
    <div>
      <div className="create-edge-form">
        <h4
          style={{
            // marginTop: "30px",
            marginBottom: "3px",
          }}
          class="text_1"
        >
          Register Edge Administrator user for Connect-On-Demand Authentication
          Service
        </h4>

        <Form layout="vertical" onFinish={registerEdgeAdministrator}>
          <Form.Item
            label="Provider Id"
            // name="ProviderId"
            class="text_1"
            // rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
          >
            <Input
              value={providerIdValue}
              onChange={(e) => {
                setProviderIdValue(e.target.value);
                setSudoState((prev) => prev + 1);
              }}
            />
          </Form.Item>

          <Form.Item
            label="User Principal Name"
            // name="UserPrincipalName"
            class="text_1"
            // rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
          >
            <Input
              value={usePrincipalValue}
              onChange={(e) => {
                setusePrincipalValue(e.target.value);
                setSudoState((prev) => prev + 1);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Name"
            // name="Name"
            class="text_1"
            // rules={[{ required: true }]}
            style={{ marginBottom: "20px" }}
          >
            <Input
              value={nameValue}
              onChange={(e) => {
                setnameValue(e.target.value);
                setSudoState((prev) => prev + 1);
              }}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: "10px" }}>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                type="primary"
                // className="button-option"
                loading={isSavingEdgeAdministrator}
                htmlType="submit"
                style={{
                  background: trueFalse() ? General_color.disable : "#1f1f1f",
                  alignItems: "center",
                  borderRadius: "31px",
                  padding: "5px 10px",
                  boxShadow: "unset",
                  cursor: trueFalse() ? "not-allowed" : "pointer",
                  fontWeight: "500",
                  border: "0px",
                  color: "white",
                  fontFamily: "ABBVoice",
                }}
                disabled={trueFalse()}
              >
                Apply
              </Button>

              <Button
                // type="primary"
                // className="button-option"
                // text="Cancel"
                style={{
                  borderRadius: "100px",
                  marginLeft: "20px",
                  fontWeight: "500",
                  color: trueFalse() ? General_color.disable : "#000",
                }}
                onClick={() => {
                  setResetThis((prev) => prev + 1);
                }}
                disabled={trueFalse()}
              >
                Reset
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Widget;
