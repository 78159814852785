const { useSelector, useDispatch } = require("react-redux");

const { setPageNotifications } = require("../reduxStore/actions");

const generateTime = function () {
  // return new Date().toLocaleTimeString("en-GB", {
  //   hour: "numeric",
  //   minute: "numeric",
  //   second: "numeric",
  // });
  return new Date().getTime();
};

const CreateGuid = () => {
  function _p8(s) {
    var p = (Math.random().toString(16) + "000000000").substr(2, 8);
    return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
  }
  return _p8() + _p8(true) + _p8(true) + _p8();
};

const scrollUp = () => {
  console.log("scrolled");
  let container = document.getElementById("allnotiContainer");
  container.scrollTop = 0;
};

const calculateTimeElapsed = (timestamp) => {
  const currentTime = new Date();
  const elapsedTime = currentTime - timestamp;

  // Convert milliseconds to seconds
  const elapsedSeconds = Math.floor(elapsedTime / 1000);

  // Calculate remaining time after removing complete minutes
  const remainingSeconds = elapsedSeconds % 60;

  // Convert remaining time to minutes
  const elapsedMinutes = Math.floor(elapsedSeconds / 60);
  const remainingMinutes = elapsedMinutes % 60;

  // Convert remaining time to hours
  const elapsedHours = Math.floor(elapsedMinutes / 60);
  const remainingHours = elapsedHours % 24;

  // Convert remaining time to days
  const elapsedDays = Math.floor(elapsedHours / 24);
  const remainingDays = elapsedDays % 30;

  // Convert remaining time to months
  const elapsedMonths = Math.floor(elapsedDays / 30);
  const remainingMonths = elapsedMonths % 12;

  // Convert remaining time to years
  const elapsedYears = Math.floor(elapsedMonths / 12);

  return {
    years: elapsedYears,
    months: remainingMonths,
    days: remainingDays,
    hours: remainingHours,
    minutes: remainingMinutes,
    seconds: remainingSeconds,
  };
};

const renderCalculatedTime = (time) => {
  let instance = ["years", "months", "days", "hours", "minutes", "seconds"];
  let elapsedTime = calculateTimeElapsed(time);
  let thisTime=""
 instance.some((occurance)=>{
    if(elapsedTime[occurance] > 0){
      thisTime = `${elapsedTime[occurance]} ${occurance}(s) ago `
      return true
    }
  })
 
  return thisTime
};

exports.generateTime = generateTime;
exports.CreateGuid = CreateGuid;
exports.scrollUp = scrollUp;
exports.renderCalculatedTime = renderCalculatedTime;
