import React from "react";
import "./ViewsContainer.css";
import AppRoutes from "./Routes";
import Footer from "../Common/Components/Footer/Footer";
import AppNotification from "../Common/Components/AppNotification/AppNotification";
import { AbbBar, AppContainer } from "@abb/abb-common-ux-react";
import { useSelector } from "react-redux";
import { IState } from "../Reducer";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { envSettings } from "../EnviornmentSettings/Settings";
import { commonAuthHelper } from "../App";
import TimeOutHandler from "../Common/Components/TimeOutHandler/TimeoutHandler";
import Toolheading from "../components/ToolHeading/Toolheading";

const ViewsConatiner = (props: RouteComponentProps) => {
  let inactiveVal = useSelector((state:any)=>state.resetInactivityTimer)
  const userLogout = () => {
    if (envSettings.connectivityMode === "connected") {
      localStorage.clear();
      sessionStorage.clear();
      document.location.replace("/");
    }
    commonAuthHelper.logOut();
  };
  const commonState = useSelector((state: IState) => state.commonState);
  return (
    <AppContainer
      className={`views-container ${
        commonState.theme === "light"
          ? "light-view-container"
          : "dark-view-container"
      }`}
      theme={commonState.theme}
    >
      {commonState.showNotification ? <AppNotification /> : <div />}
      {/* <AbbBar
        blackAndWhiteLogo={false}
        productName="Edgenius Configuration Utility"
      />
      <Header /> */}
      <Toolheading />
      <AppRoutes />
      <TimeOutHandler onLogout={userLogout} resetTimer = {inactiveVal}/>
      <Footer />
    </AppContainer>
  );
};
export default withRouter(ViewsConatiner);
