import React, { useState, useEffect } from "react";
import "./Widget.css";

import { Form, Select, InputNumber, Button, Input, message } from "antd";

import { useDispatch, useSelector } from "react-redux";

import Axios from "axios";
import {
  isUnsavedEdgeSettings,
  resetInactiveTimer,
  setBrokerEdgeSettings,
  setPageNotifications,
} from "../../reduxStore/actions";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { CreateGuid, generateTime } from "../../constants/commonFunction";
import {
  GeneralConstants,
  General_color,
  
} from "../../constants/GeneralConstants";

function Widget() {
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  let FetchedBrokerSettingsdata = useSelector(
    (state) => state.setBrokerEdgeSettings
  );
  let detailsData = useSelector((state) => state.setEdgeDetails);
  let tempSettings = useSelector((state) => state.setBrokerEdgeSettings);
  let [BrokerSettingsdata, setBrokerEdgeSettings_] = useState({});
  const [sudoState, setSudoState] = useState(0);
  const [handlelReset, setHandleReset] = useState(0);
  const [collectedValues, setCollectedValues] = useState([]);
  const [changedValues, setChangedValues] = useState({});
  let initialInactive = useSelector((state)=>state.resetInactivityTimer)
  const resetTimer = () => {

    dispatch(resetInactiveTimer({payload:initialInactive+1}))
  }
  useEffect(() => {
    setSudoState((prev) => prev + 1);

    setChangedValues(JSON.parse(JSON.stringify(FetchedBrokerSettingsdata)));
    setBrokerEdgeSettings_(tempSettings);
    setIsChanged(false);
    setCollectedValues([]);
  }, [FetchedBrokerSettingsdata, handlelReset]);

  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);

  const [ischanged, setIsChanged] = useState(false);

  // let [changedValues, setChangedVAlues] = useState([]);
  const [payload, setPayload] = useState({});

  const collectChangeValues = (value, target) => {
    //  console.log(changedValues)
    changedValues.properties.map((e, i) => {
      if (e.targets === target) {
        changedValues.properties[i].value = value;

        setChangedValues(changedValues);
        setBrokerEdgeSettings_(changedValues);
        let foundIndex = collectedValues.findIndex(
          (item) => item.targets === target
        );

        if (foundIndex == -1) {
          collectedValues.push({ value: value, targets: target });
        } else {
          collectedValues[foundIndex].value = value;
        }
      }
    });

    // setSudoState((prev) => prev + 1);
    setPayload({
      sectionName: BrokerSettingsdata.sectionName,
      parentTypeId: BrokerSettingsdata.parentTypeId,
      properties: collectedValues,
    });
    // console.log(changedValues.properties, BrokerSettingsdata.properties, collectedValues)
    if (JSON.stringify(changedValues) === JSON.stringify(tempSettings)) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  };

  let notiMsg;
  let oldNotifications = useSelector((state) => state.setPageNotifications);

  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
    let previosData = [];
    if (oldNotifications.newData.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };

  const handle_save = () => {
    setIsSaving(true);
    // Handle second button click
    let data = JSON.stringify([payload]);

    let postEdgeSettingsURL = apiEndPoints.SET_ALL_EDGE_SETTINGS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: postEdgeSettingsURL,
      
      data: data,
    };
    resetTimer()
    Axios.request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));

        // const NewMainData = FetchedBrokerSettingsdata;

        // changedValues.map((prop) => {
        //   let indextoupdate = NewMainData.properties.findIndex(
        //     (item) => item.targets == prop.targets
        //   );

        //   if (indextoupdate != -1) {
        //     NewMainData.properties[indextoupdate].value = prop.value;
        //   }
        // });
        console.log(changedValues);
        dispatch(setBrokerEdgeSettings({ payload: changedValues }));

        setIsSaving(false);
        setIsChanged(false);

        data = JSON.parse(data)[0];

        AddEdgeSettingsNotification(
          `${data.sectionName} applied successfully`,
          `edgeSettingUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: true,
            modalKey: `edgeSettingUpdate_${CreateGuid()}`,
            setting_type: data.parentTypeId.split("/")[1],
            settingSectionName: data.sectionName,
            edgeName: localStorage.getItem("edgeName"),
            appName: "",
            successMessage: `${data.sectionName} applied successfully`,
            time: generateTime(),
            isComplete: false,
            limit: 3,
            currStateValue: 0,
            icon: "good",
          }
        );
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
        notiMsg = "Applying broker settings failed, please try again";
        AddEdgeSettingsNotification(
          `${notiMsg}`,
          `AppRelatedUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: true,
            modalKey: `AppRelatedUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            appName: "",
            successMessage: `${notiMsg}`,
            time: generateTime(),
            isComplete: true,
            limit: 3,
            currStateValue: 0,
            icon: "failed",
          }
        );
        message.error(notiMsg);
      });
  };

  const renderInputs = (ele) => {
    switch (ele.presentationType) {
      case "Select":
        return (
          <>
            <div
              style={{
                display: "flex",
                rowGap: "5px",
                flexDirection: "column",
              }}
            >
              <div style={{ fontWeight: 500 }} className="text_1">
                {ele?.name}
              </div>
              <div
                style={{
                  width: "100%",
                  height: "40px",
                  padding: "8px, 12px, 8px, 12px",
                  borderRadius: "4px",
                  border: "100px",
                  gap: "8px",
                }}
              >
                {/* <Switch defaultChecked /> */}
                <select
                  defaultValue={ele.value}
                  onChange={(e) =>
                    collectChangeValues(e.target.value, ele.targets)
                  }
                  style={{
                    padding: "5px 8px",
                    borderRadius: "5px",
                    width: "100%",
                    borderColor: "#d9d9d9",
                  }}
                >
                  <option></option>
                  {ele?.valueDomain?.map((e, k) => {
                    if (e.includes("@")) {
                      let refined_values = e.split("@");
                      return (
                        <option value={refined_values[1]} key={k}>
                          {refined_values[1]}
                        </option>
                      );
                    } else {
                      return (
                        <option value={e} key={k}>
                          {e}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </div>
            <div className="text_2">
              {ele?.description == "Log Level." ? "" : ele?.description}
            </div>
          </>
        );
      case "CheckBox":
        return (
          <>
            <div
              style={{
                display: "flex",
                columnGap: "10px",
                alignItems: "center",
              }}
            >
              <div
              // style={{ width: "60px" }}
              >
                {/* <checkbox
                  onChange={(e) =>
                    collectChangeValues(e.target.checked, ele.targets)
                  }
                  checked={ele.value}
                >asdf</checkbox> */}

                <input
                  type="checkbox"
                  checked={
                    ele.value == "False" ||
                    ele.value == "false" ||
                    ele.value == null ||
                    ele.value == false
                      ? false
                      : true
                  }
                  onChange={(e) => {
                    ele.value = e.target.checked;
                    collectChangeValues(e.target.checked, ele.targets);
                  }}
                />
              </div>
              <div
                style={{ fontWeight: 500, marginBottom: "3px" }}
                className="text_1"
              >
                {ele?.name}
              </div>
            </div>
            <div className="text_2">{ele?.description}</div>
          </>
        );
      case "Text":
        return (
          <div
            style={{
              display: "flex",
              rowGap: "5px",
              flexDirection: "column",
            }}
          >
            <div style={{ fontWeight: 500 }} className="text_1">
              {ele?.name}
            </div>
            <div
              style={{
                width: "100%",
                height: "40px",
                padding: "8px, 12px, 8px, 12px",
                borderRadius: "4px",
                border: "100px",
                gap: "8px",
              }}
            >
              <Input
                value={ele?.value}
                onChange={(e) =>
                  collectChangeValues(e.target.value, ele.targets)
                }
              />
            </div>
            <div className="text_2">{ele?.description}</div>
          </div>
        );
      default:
        break;
    }
  };

  const trueFalse = () => {
    if (ischanged) {
      dispatch(isUnsavedEdgeSettings({ payload: true }));
      return false;
    } else {
      dispatch(isUnsavedEdgeSettings({ payload: false }));
      return true;
    }
  };

  return (
    <div className="App reverseproxy-settings-container">
      <div className="reverse-proxy-form">
        <Form layout="vertical">
          {BrokerSettingsdata?.properties?.map((ele, key) => (
            <Form.Item style={{ marginBottom: "20px" }} key={key}>
              {renderInputs(ele)}
            </Form.Item>
          ))}
        </Form>

        <div
          className="reverse-proxy-button"
          style={{ marginTop: "50px", display: "flex" }}
        >
          <Button
            loading={isSaving}
            style={{
              background: trueFalse() ? General_color.disable : "#1f1f1f",
              alignItems: "center",
              borderRadius: "31px",
              padding: "5px 10px",
              cursor: "pointer",
              fontWeight: "500",
              border: "0px",
              color: "white",
              marginLeft: "auto",
            }}
            onClick={handle_save}
            disabled={trueFalse()}
          >
            Apply
          </Button>
          <Button
            onClick={() => setHandleReset((prev) => prev + 1)}
            style={{
              borderRadius: "100px",
              marginLeft: "20px",
              fontWeight: "500",
              color: trueFalse() ? General_color.disable : "#000",
            }}
            disabled={trueFalse()}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Widget;
