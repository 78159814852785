import React, { useEffect, useState } from "react";
import { Menu, message, Table, Typography } from "antd";
import Axios from "axios";
import "./AboutPage.css";
import Heading from "../SectionHeading/Heading";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetInactiveTimer } from "../../reduxStore/actions";

const AboutPage = () => {
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState("1");
  const [apiVersion, setApiVersion] = useState("");
  const [apiLoad, setApiLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageDataLength = 10;
  const [desirePageData, setDesirePageData] = useState({
    PageNumber: "1",
    PageSize: pageDataLength,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    if (tab === "userguide") {
      setSelectedMenu("3");
    }
  }, [location.search]);

  const handleMenuClick = ({ key }) => {
    setSelectedMenu(key);
  };

  useEffect(() => {
    setApiLoad(true);

    let apiVersion = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.API_VERSION,
    };
    Axios.request(apiVersion)
      .then((response) => {
        setApiVersion(response?.data?.version);
        setApiLoad(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const downloadJSONFile = async () => {
    try {
      const response = await fetch(
        "https://edgeniusdev.blob.core.windows.net/edge-os/edgenius-cod-os/edge-config.json"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: "application/json",
      });
      saveAs(blob, "edge-config.json");
    } catch (error) {
      console.log(error);
    }
  };
  const pkiData = {
    PKI_RA_SERVER_ADDRESS:
      "https://ptnr-pki-scep.bbtest.net/scep/2.16.840.1.113733.1.16.1.3.1.4.1.127290527/cgi-bin/pkiclient.exe",
    PKI_CERTIFICATE_OU: "DPS",
    PKI_CERTIFICATE_O: "ABB Information Systems Ltd.",
    PKI_CA_AUTH_HASH: "1F7620B115DE521F1D71AD2ADF7D8FE1",
    IDSERVICEURL: "abiapiapmgs03euwtst.azure-api.net",
    PKI_CERT_EXPIRE_THRESHOLD: 30,
    PKI_POLL_ATTEMPTS: 11,
    PKI_POLL_INTERVAL: 10000,
    REGISTER_DEV_ID: 0,
    DEVICEID_DUPLICATION_CHECK_REQUIRED: 0,
    PKI_CERT_VALIDITY_CHECK_WAIT_TIME: 5,
    TPM_CLEAR: true,
    DPS_PROVISIONING_HOST: "global.azure-devices-provisioning.net",
    DPS_ID_SCOPE: "0ne00CA1466",
  };

  return (
    <div className="about-page-container">
      <div className="about-menu-container">
        <Menu
          mode="inline"
          selectedKeys={[selectedMenu]}
          onClick={handleMenuClick}
          className="about-menu"
        >
          <Menu.Item key="1" className="menuItem">
            About
          </Menu.Item>
          <Menu.Item key="2" className="menuItem">
            PKI configuration
          </Menu.Item>
          <Menu.Item key="3" className="menuItem">
            User guide
          </Menu.Item>
        </Menu>
      </div>
      <div className="about-content-container">
        <div className="about-content">
          <span className="heading-container">
            <Heading
              text={selectedMenu === "3" ? "" : `Edgenius Management Portal`}
            />
            {apiLoad && selectedMenu === "1" ? <SpinnerLoader /> : ""}
          </span>

          {selectedMenu === "1" && (
            <div>
              {!apiLoad && (
                <div
                  className="Description_about"
                  style={{ fontFamily: "ABBVoice", fontWeight: "100" }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      marginTop: "20px",
                    }}
                  >
                    Version
                  </span>
                  <br />
                  <div
                    style={{
                      fontSize: "16px",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "5px",
                      marginTop: "10px",
                    }}
                  >
                    <span> edge-management-console:1.0.531 </span>

                    <span>edge-management-console-api:{apiVersion} </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {selectedMenu === "2" && (
            <div className="pki-content-container">
              <div className="pki-json-container" style={{ width: "80%" }}>
                {Object.entries(pkiData).map(([key, value]) => (
                  <div
                    key={key}
                    className="key-value-item"
                    style={{ fontFamily: "ABBVoice", fontSize: "16px " }}
                  >
                    <span className="pki-key">{key} : </span>{" "}
                    <span className="pki-value"> {value}</span>
                  </div>
                ))}
              </div>
              <div>
                <button className="download-button" onClick={downloadJSONFile}>
                  Download as a file
                </button>
              </div>
            </div>
          )}

          {selectedMenu === "3" && (
            <div
              className="Description_about"
              style={{
                fontFamily: "ABBVoice",
                paddingTop: "10px",
                fontSize: "16px",
              }}
            >
              <p>
                Please visit the following links for a detailed user
                documentation:
              </p>
              <ul>
                <li>
                  Edge Installation Guide:{" "}
                  <a
                    className="Hyper_link"
                    href="https://internal.iiot.automation.abb.com/docs/edgenius/install/CentrallyManageEdge/centrallyManagedEdge"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Centrally Managed Edge | IIoT Information Portal (ABB)
                  </a>
                </li>
                <li>
                  Edge Management Portal (EMP):{" "}
                  <a
                    className="Hyper_link"
                    href="https://internal.iiot.automation.abb.com/docs/edgenius/management-tools/edgenius-management-console/management-portal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ABB Edgenius Management Portal Overview | IIoT Information
                    Portal
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
