import { Button, Col, Collapse, Dropdown, Row, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import Heading from "../../components/SectionHeading/Heading";
import ReleaseNotes from "../../components/ReleaseNotes/ReleaseNotes";
import ReactMarkdown from "react-markdown";
import { saveAs } from "file-saver";
import axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { FaChevronDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { resetInactiveTimer } from "../../reduxStore/actions";
const AppReleaseNote = (props) => {
  if (props.releaseNote) {
    let formattedDate =
      props.releaseNote.releaseDate == "%%ReleaseDate%%"
        ? "%%ReleaseDate%%"
        : atob(props.releaseNote.releaseDate || "");
    formattedDate = formattedDate.replace(/T/g, " ");
    formattedDate = formattedDate.replace(/[+].*/g, "");
    return (
      <>
        <div className="release-notes-header-wrap custom-release-notes-header-wrap">
          {props.releaseNote?.version && (
            <div className="release-note-version custom-release-note-version">{`VERSION  ${props.releaseNote?.version}`}</div>
          )}
          <div className="releasenote-text custom-releasenote-text">
            <ReactMarkdown
              children={
                props.releaseNote?.releaseNotes == "%%ReleaseNotes%%"
                  ? "%%ReleaseNotes%%"
                  : atob(props.releaseNote?.releaseNotes || "")
              }
            />
          </div>
          <div className="release-note-time-stamp">{`${formattedDate}`}</div>
        </div>

        {props.releaseNote?.releaseKnownIssues && (
          <div className="releasenote-known-issues">
            <p>Key Updates</p>
            <ReactMarkdown
              children={
                props.releaseNote?.releaseKnownIssues ==
                "%%ReleaseKnownIssues%%"
                  ? "%%ReleaseKnownIssues%%"
                  : atob(props.releaseNote?.releaseKnownIssues || "")
              }
            />
          </div>
        )}

        {props.releaseNote?.releaseKnownIssues_prerequist && (
          <div className="releasenote-known-issues">
            <ReactMarkdown
              children={
                props.releaseNote?.releaseKnownIssues_prerequist ==
                "%%ReleaseKnownIssues%%"
                  ? "%%ReleaseKnownIssues%%"
                  : atob(props.releaseNote?.releaseKnownIssues_prerequist || "")
              }
            />
          </div>
        )}
      </>
    );
  } else {
    return <></>;
  }
};
const DownloadsComponent = () => {
  const releaseNote = {
    releaseHistory: [
      {
        // version: "1.10.0",
        releaseNotes:
          "RWRnZW5pdXMgT1MgaXMgYXZhaWxhYmxlIHRvIGluc3RhbGwgRWRnZW5pdXMgYXMgYW4gQXBwbGljYXRpb24gUGxhdGZvcm0gb24gYW4gRWRnZSBkZXZpY2UuIEl0IGlzIGJhc2VkIG9uIFJlZGhhdCBFbnRlcnByaXNlIExpbnV4LiBJdCBzdXBwb3J0cyBjZW50cmFsaXplZCBtYW5hZ2VtZW50IG9mIEVkZ2UgZnJvbSBFZGdlbml1cyBNYW5hZ2VtZW50IENvbnNvbGUuIA==",
        //    releaseDate: "MjAyMC0xMS0wNVQwODoyMzo0MyswMDowMAo=",
        modules: [
          {
            name: "value",
            value: null,
          },
        ],
      },
      {
        version: "0.1.0-rc.239",
        releaseNotes: "KipJbnRlcm5hbCBEZXYgUmVsZWFzZSoq",
        releaseKnownIssues:
          "LSBCdWcgZml4ZXMgYW5kIGltcHJvdmVtZW50cyBmb3IgRUNTLCBPcmNoZXN0cmF0b3IgYW5kIFJlbW90ZSBtZXRob2QgaW52b2NhdGlvbi4gCi0gT1MgdXBkYXRlIHNlcnZpY2UgYnVnIGZpeGVzLg==",
        releaseDate: "QXVndXN0IDEydGggMjAyNA==",
        modules: [
          {
            name: "value",
            value: null,
          },
        ],
      },
      {
        version: "0.1.0-rc.189",
        releaseNotes: "KipJbnRlcm5hbCBEZXYgUmVsZWFzZSoq",
        // releaseNotes:
        //   "VGhpcyBpcyB0aGUgZmlyc3QgcmVsZWFzZSBwcm92aWRpbmcgZWZmaWNpZW50IGFjY2VzcyBiZXR3ZWVuIHRoZSBEQ1Mgc3lzdGVtIGFuZCBFZGdlLgoKLSBDcmVhdGUgZGlnaXRhbCB0d2lucyBvZiB0aGUgRENTIHN5c3RlbSBvYmplY3RzLgotIFB1Ymxpc2ggcHJvcGVydGllcywgYWxhcm1zIGFuZCBldmVudHMuCg==",
        releaseKnownIssues:
          "LSBCdWcgZml4ZXMgYW5kIGltcHJvdmVtZW50cyBmb3IgRWRnZSBBZ2VudCBhbmQgRWRnZSBjb25maWcgc2VydmljZS4gCi0gRWRnZW5pdXMgQ29ja3BpdCBlbmhhbmNlbWVudHM6IGNvbmZpZ3VyYXRpb24gdXNpbmcgYSBmaWxlIHRvIG1pbmltaXplIGh1bWFuIGVycm9ycy4K",
        releaseDate: "QXVndXN0IDA1dGggMjAyNA==",
        modules: [
          {
            name: "value",
            value: null,
          },
        ],
      },

      {
        version: "0.1.0-rc.145",
        releaseNotes: "KipJbnRlcm5hbCBEZXYgUmVsZWFzZSoq",
        // releaseNotes:
        //   "VGhpcyBpcyB0aGUgZmlyc3QgcmVsZWFzZSBwcm92aWRpbmcgZWZmaWNpZW50IGFjY2VzcyBiZXR3ZWVuIHRoZSBEQ1Mgc3lzdGVtIGFuZCBFZGdlLgoKLSBDcmVhdGUgZGlnaXRhbCB0d2lucyBvZiB0aGUgRENTIHN5c3RlbSBvYmplY3RzLgotIFB1Ymxpc2ggcHJvcGVydGllcywgYWxhcm1zIGFuZCBldmVudHMuCg==",
        releaseKnownIssues:
          "LSBCdWcgZml4ZXMgYW5kIGltcHJvdmVtZW50cyBmb3IgRWRnZSBBZ2VudCBhbmQgRWRnZSBjb25maWcgc2VydmljZS4KLSBHUFUgZW5hYmxlbWVudCBmcm9tIEVkZ2VuaXVzIENvY2twaXQuIFN1cHBvcnQgZm9yIE52aWRpYSBHUFUgc2VydmljZXMtIHVzZXIgY2FuIGVuYWJsZSBHUFUgc2VydmljZXMgb24gdGhlIEVkZ2UgdmlhIEVkZ2VuaXVzIENvY2twaXQgKGtub3duIGlzc3VlOiBDYW5ub3QgZGlzYWJsZSBHUFUgZHJpdmVyIG9uY2UgZW5hYmxlZCkKLSDigaBPdGVsIGVuZHBvaW50IHdpdGggZ3JwYyBwcm90b2NvbCBpcyBzdXBwb3J0ZWQgZm9yIExhYiBlbnZpcm9ubWVudC4KLSBFZGdlbml1cyBMaWNlbnNlIEZyYW1ld29yayBpcyBhdmFpbGFibGUgYXMgcGFydCBvZiBFZGdlbml1cyBPUw==",
        releaseDate: "SnVseSAxMHRoIDIwMjQ=",
        modules: [
          {
            name: "value",
            value: null,
          },
        ],
      },
    ],
  };
  const techincalSpecs = {
    releaseHistory: [
      {
        // version: "0.1.0-rc.45",
        // releaseNotes:
        //   "VGhpcyBpcyB0aGUgZmlyc3QgcmVsZWFzZSBwcm92aWRpbmcgZWZmaWNpZW50IGFjY2VzcyBiZXR3ZWVuIHRoZSBEQ1Mgc3lzdGVtIGFuZCBFZGdlLgoKLSBDcmVhdGUgZGlnaXRhbCB0d2lucyBvZiB0aGUgRENTIHN5c3RlbSBvYmplY3RzLgotIFB1Ymxpc2ggcHJvcGVydGllcywgYWxhcm1zIGFuZCBldmVudHMuCg==",
        releaseKnownIssues_prerequist:
          "LSBNaW5pbXVtIEhhcmR3YXJlIFNwZWNpZmljYXRpb246IDQgQ29yZSBDUFUsIDMyIEdCIFJBTSBhbmQgNTUwIEdCIEhERC4gCi0gVHJ1c3RlZCBQbGF0Zm9ybSBNb2R1bGUgKFRQTSkgaXMgbXVzdCBmb3IgYSBjZW50cmFsbHkgbWFuYWdlZCBlZGdlLgotIEEgZGVwZW5kYWJsZSBpbnRlcm5ldCBjb25uZWN0aW9uIGFuZCBuZXR3b3JraW5nIHNwZWNpZmljcyBzdWNoIGFzIHByb3h5IHNldHRpbmdzLCBETlMgY29uZmlndXJhdGlvbiwgTlRQIHN5bmNocm9uaXphdGlvbiwgZXRjLiBzaG91bGQgYmUgYXZhaWFibGUgdG8gY29uZmlndXJlIEVkZ2UgZm9yIHJlbW90ZSBjb25lY3Rpdml0eS4KLSBGb3IgRWRnZSBib290c3RyYXBwaW5nIHVzaW5nIEVkZ2VuaXVzIENvY2twaXQsIGFuIEVkZ2UgY29uZmlnIGZpbGUgaXMgcmVxdWlyZWQuIERvd25sb2FkIHRoZSBmaWxlIHVzaW5nIExpbmsgYmVsb3cuKE5ldyk=",
        // releaseDate: "SnVseSAxMHRoIDIwMjQ=",
        modules: [
          {
            name: "value",
            value: null,
          },
        ],
      },
    ],
  };
  const [options, setOptions] = useState([
    "Operating System",
    "Integrated Packages",
    "eV5000 (SNO) configuration",
  ]);
  const [disconnectedEdgeSoftwares, setdisconnectedEdgeSoftwares] = useState(
    []
  );
  const [downloadLink, setDownloadLink] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [customExtendedSoftwares, setcustomExtendedSoftwares] = useState([]);
  const [selectedView, setselectedView] = useState(options[0]);
  const [customHeight, setCustomHeight] = useState(0);
  useEffect(() => {
    fetchSoftwaresData();
    fettchCustomAppData();
  }, []);
  let dispatch = useDispatch();
  let initialInactive = useSelector((state) => state.resetInactivityTimer);
  const resetTimer = () => {
    dispatch(resetInactiveTimer({ payload: initialInactive + 1 }));
  };
  const fetchSoftwaresData = async () => {
    resetTimer();
    await axios
      .get(`${apiEndPoints.edgeniusSoftwareList}`)

      // .then((res) => res.json())
      .then((response) => {
        setdisconnectedEdgeSoftwares(response.data);
      });
  };

  const fettchCustomAppData = async () => {
    resetTimer();
    await axios
      .get(`${apiEndPoints.extendedCustomAppList}`)

      // .then((res) => res.json())
      .then((response) => {
        setcustomExtendedSoftwares(response.data);
      });
  };
  const getDownloadLinkforEdgenusSoftware = (filename) => {
    setDownloadLink("");
    setShowConfirmation(true);
    resetTimer();
    axios
      .post(`${apiEndPoints.edgeSoftwareGenerateLink}`, {
        name: filename,
      })
      // .then((res) => res.json())
      .then((response) => {
        setDownloadLink(response.data.downloadableLink);
      });
  };
  const getDownloadLinkforExtendedApps = (filename) => {
    setDownloadLink("");
    setShowConfirmation(true);
    resetTimer();
    axios
      .post(
        `${apiEndPoints.extendedCustomAppGenerateLink}`,

        {
          name: filename,
        }
      )

      .then((response) => {
        setDownloadLink(response.data.downloadableLink);
      });
  };
  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"][d]
    }`;
  }
  const [apiLoad, setApiLoad] = useState(false);
  const [osDetails, setOsDetails] = useState({});
  const adjustHeight = () => {
    let a = 0;
    let getMainContainer = document?.getElementsByClassName("gridContainer");
    let getTheAboveApp = getMainContainer?.[0]?.getElementsByClassName("App");
    console.log(getTheAboveApp);

    let appHeight = getTheAboveApp?.[0]?.clientHeight;

    let finalHeight = getMainContainer?.[0]?.clientHeight - 100;
    // console.log("value of inner height",finalHeight, appHeight)
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);

    setApiLoad(false);
    let config = {
      method: "get",
      url: `${apiEndPoints.FETCH_OS_DONLOAD_URL}`,
    };
    resetTimer();
    axios
      .request(config)
      .then((response) => {
        let hyfenCount = {};
        let dataReturned = response.data;
        let version = dataReturned.substring(dataReturned.lastIndexOf("/") + 1);
        let temp_version = version.split(".iso")[0];
        temp_version.split("").map((letter, key) => {
          if (letter === "-") {
            hyfenCount[key] = true;
          }
        });
        let indexxx =
          Object.keys(hyfenCount)[Object.keys(hyfenCount).length - 2];

        let extractedVersion = temp_version.substring(parseInt(indexxx) + 1);

        setOsDetails({
          ...osDetails,
          downloadUrl: response.data,
          version: extractedVersion,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setApiLoad(true);
      });
  }, []);

  const changeView = (view) => {
    setselectedView(view);
  };
  const TableComponent = () => {
    const columns = [
      {
        title: "Edgenius Integrated Packages",
        dataIndex: "systemType",
        key: "systemType",
        render: (text) => <strong>{text}</strong>,
        width: "25%",
      },
      {
        title: "File Name",
        dataIndex: "name",
        key: "name",
        width: "25%",
        render: (text) => text,
      },
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
        width: "10%",
      },
      {
        title: "Updated on",
        dataIndex: "date",
        key: "date",
        width: "20%",
      },
      {
        title: "Size",
        dataIndex: "size",
        key: "size",
        width: "10%",
      },
      {
        title: "Download",
        dataIndex: "downloadLink",
        key: "downloadLink",
        width: "10%",
        render: (record) => (
          <a
            href="#"
            style={{ color: "blue" }}
            onClick={() => handleDownloadLink(record.systemType, record.key)}
          >
            link
          </a>
        ),
      },
    ];

    const dummyData = [
      {
        sno: 1,
        systemType: "Ev5000",
        name: "Edgenius_Dejna_dummy3.1.zip",
        version: "3.1.0.0",
        date: "Aug 30th, 2024",
        size: "64.5 GB",
        downloadLink: "link",
      },
      {
        sno: 2,
        systemType: "Ev1000 Locally managed Gateway",
        name: "Edgenius_Elbe_dummy3.2.zip",
        version: "1.0.1",
        date: "Aug 30th, 2024",
        size: "64.5 GB",
        downloadLink: "link",
      },
      {
        sno: 3,
        systemType: "Locally managed Gateway",
        name: "Edgenius_LMS_dummy3.2.zip",
        version: "1.0.33",
        date: "April 30th, 2024",
        size: "14.5 GB",
        downloadLink: "link",
      },
    ];

    const dataSource = useMemo(() => {
      return dummyData.sort((a, b) => new Date(b.date) - new Date(a.date));
    }, []);

    const handleDownloadLink = (systemType, key) => {
      // Handle download link generation
      if (systemType.includes("Ev5000")) {
        getDownloadLinkforEdgenusSoftware(key);
      } else {
        getDownloadLinkforExtendedApps(key);
      }
    };
    const dataSource_ = useMemo(() => {
      const mergedData = [
        ...disconnectedEdgeSoftwares.map((val, index) => ({
          sno: index + 1,
          systemType: "vE5000 Dissconnected Edgenius",
          name: val.key,
          description: "",
          size: formatBytes(val.size),
          releaseDate: val.lastModified.toString().split("T")[0],
          downloadLink: "",
          key: val.key,
        })),
        ...customExtendedSoftwares.map((val, index) => ({
          sno: disconnectedEdgeSoftwares.length + index + 1,
          systemType: "vE1000 Dissconnected Edgenius",
          name: val.key,
          description: "",
          size: formatBytes(val.size),
          releaseDate: val.lastModified.toString().split("T")[0],
          downloadLink: "",
          key: val.key,
        })),
        ...customExtendedSoftwares.map((val, index) => ({
          sno:
            disconnectedEdgeSoftwares.length +
            customExtendedSoftwares.length +
            index +
            1,
          systemType: "Custom Applications for vE5000",
          name: val.key,
          description: "",
          size: formatBytes(val.size),
          releaseDate: val.lastModified.toString().split("T")[0],
          downloadLink: "",
          key: val.key,
        })),
      ];

      return mergedData.sort(
        (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)
      );
    }, [disconnectedEdgeSoftwares, customExtendedSoftwares]);

    return (
      <div className="softwareScreen">
        <Table
          columns={columns}
          dataSource={dataSource}
          rowKey="sno"
          pagination={false}
          bordered
        />
      </div>
    );
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: "Technical Specifications",
      children: (
        <div>
          {techincalSpecs?.releaseHistory?.length > 0 &&
            techincalSpecs.releaseHistory.map((release) => (
              <>
                <AppReleaseNote releaseNote={release} />
              </>
            ))}
          <div style={{ marginLeft: "40px" }}>
            A PKI configuration file is needed to connect the Edge to the Edge
            Management Portal and Ability services through Edgenius Cockpit.
            Check below navigation. <br />
            Home &gt; Documentation &gt; PKI configuration
          </div>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: "Release Notes",
      children: (
        <div>
          {releaseNote?.releaseHistory?.length > 0 &&
            releaseNote.releaseHistory.map((release) => (
              <>
                <AppReleaseNote releaseNote={release} />
              </>
            ))}
        </div>
      ),
      style: panelStyle,
    },
    {
      collapsible: "disabled",
      key: "3",
      label: "Open Source Lisence",
      children: <span>{}</span>,
      style: panelStyle,
    },
    {
      collapsible: "disabled",
      key: "4",
      label: "Previous Versions",
      children: <span>{}</span>,
      style: panelStyle,
    },
  ];

  const [selectedKey, setSelectedKey] = useState(1);
  const handleMenuClick = (e) => {
    console.log("click", e.key);
    setSelectedKey(e.key);
  };

  const handleButtonClick = (e) => {
    window.location.href = osDetails.downloadUrl;
  };
  const items = [
    {
      label: "Download",
      key: "1",
      // icon: <UserOutlined />,
    },
    {
      label: "Download and Install",
      key: "2",
      // icon: <UserOutlined />,
    },
    {
      label: "Install",
      key: "3",
      // icon: <UserOutlined />,
      danger: true,
      disabled: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const panelStyle = {
    marginBottom: 10,
    background: "#ebebeb",
    borderRadius: "5px",
    border: "none",
  };
  return (
    <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
      <Col
        flex="1 1 525px"
        style={{
          columnGap: "10px",
          display: "grid",
          rowGap: "10px",
        }}
      >
        <Row style={{ gridGap: "10px" }}>
          <Col
            className="gridContainer"
            flex="1"
            style={{
              borderRadius: "10px",
              height: "90vh",
            }}
          >
            <div className="App">
              <span
                className="Heading_container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "10px",
                  width: "100%",
                  borderBottom: "1px solid #f0f0f0",
                }}
              >
                <Heading text="Downloads" />
              </span>
              <div
                style={{
                  height: `${customHeight}px`,

                  overflow: "auto",
                }}
              >
                <div
                  className="Alloptions"
                  style={{ justifyContent: "flex-start", gap: "20px" }}
                >
                  {options?.length > 0 &&
                    options?.map((option, index) => (
                      <span
                        onClick={() =>
                          option !== "eV5000 (SNO) configuration"
                            ? changeView(option)
                            : {}
                        }
                        className="heading_2"
                        style={{
                          // background: selectedView == option ? "#c9c9c9" : "",
                          borderBottomColor:
                            selectedView == option ? "#666262" : "",
                          // color:index!==0&&"#bfbfbf"
                          color:
                            option !== "eV5000 (SNO) configuration"
                              ? "#000"
                              : "#989898",
                        }}
                      >
                        {option}
                      </span>
                    ))}
                </div>

                <div style={{ fontFamily: "ABBVoice", padding: "10px 10px" }}>
                  {selectedView === "Integrated Packages" && <TableComponent />}
                  {selectedView === "Operating System" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "30px",
                          border: "1px solid #e2e2e2",
                          padding: "80px 50px",
                          marginTop: "10px",
                          borderRadius: "10px",
                          boxShadow: "0px 0px 40px -10px #e2e2e2",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "ABBVoice-bold",
                            fontSize: "29px",
                          }}
                        >
                          Edgenius OS
                        </span>
                        <span
                          style={{
                            fontFamily: "ABBVoice-medium",
                            fontSize: "18px",
                          }}
                        >
                          {osDetails?.version ? (
                            `Version ${osDetails.version}`
                          ) : (
                            <SpinnerLoader />
                          )}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            gap: "50px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* <span>Release date: August 05th 2024</span> */}
                          <span>File size: 4.45 GB</span>
                          <a href={osDetails.downloadUrl} target="_blank">
                            <Button
                              type="primary"
                              // className="button-option"
                              // htmlType="submit"

                              // text="Register Identity Provider"
                              style={{
                                background: "#1f1f1f",
                                cursor: "pointer",
                                alignItems: "center",
                                borderRadius: "31px",
                                padding: "5px 15px",
                                boxShadow: "unset",
                                fontWeight: "500",
                                border: "0px",
                                color: "white",
                                fontFamily: "ABBVoice",
                              }}
                            >
                              Download Now
                            </Button>
                          </a>
                        </div>
                      </div>
                      <div style={{ marginTop: "30px" }}>
                        <Collapse
                          defaultActiveKey={["1"]}
                          style={{
                            background: "transparent",
                            border: "none",
                            fontFamily: "ABBVoice",
                          }}
                          items={getItems(panelStyle)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DownloadsComponent;
