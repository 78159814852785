import React, { useEffect, useState } from "react";
import * as PageRoutes from "../../../constants/PageRoutes";
import axios from "axios";

let logOutTimeOut: any;
interface timeoutHandlerProps {
  onLogout: () => void;
  resetTimer: any;
}
const TimeOutHandler = (props: timeoutHandlerProps) => {
  const signOutTime = 1800000; //3min
  const startTimeOut = () => {
    logOutTimeOut = setTimeout(logOut, signOutTime);
  };

  const resetTimeout = () => {
    clearTimeout(logOutTimeOut);
    startTimeOut();
  };

  // useEffect(()=>{
  //   resetTimeout()
  //   console.log("this runned timer reset")
  // },[props.resetTimer])

  // const signOutTime = 20000;

  axios.interceptors.request.use(
    (config) => {
      // When an API call is initiated, reset the timeout
      resetTimeout();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  React.useEffect(() => {
    const eventsList = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
      "keydown",
    ];
    eventsList.map((event) => {
      document.addEventListener(event, resetTimeout);
    });
    startTimeOut();
    return () => {
      eventsList.map((event) => {
        document.removeEventListener(event, resetTimeout);

        clearTimeout(logOutTimeOut);
      });
    };
  }, []);

  let keys = Object.keys(PageRoutes.breadcrumbNameMap);
  let saveTheRoutes: { route: string; value: string | null }[] = [];
  // let prev_edgeName: any = "";
  const logOut = () => {
    keys.map((route) => {
      saveTheRoutes.push({
        route,
        value: localStorage.getItem(route),
      });
    });
    // prev_edgeName = localStorage.getItem("edgeName");
    props.onLogout();
    saveTheRoutes.map((route) => {
      if (route.value == null) {
        return;
      }
      localStorage.setItem(route.route, route.value);
    });
    // localStorage.setItem("edgeName", prev_edgeName);
  };
  return <div />;
};

export default TimeOutHandler;
