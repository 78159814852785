import "./EdgeOverview.css";
import Heading from "../SectionHeading/Heading";
import { PieChart, Pie, Cell } from "recharts";
import { useSelector } from "react-redux";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { useState, useEffect } from "react";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { General_color } from "../../constants/GeneralConstants";

function EdgeOverview() {
  const matricsData = useSelector((state) => state.setData.data);
  const matricsData_isLoading = useSelector((state) => state.setData.isLoading);
  const graphData = useSelector((state) => state.setGraph.data);
  const graphData_isLoading = useSelector((state) => state.setGraph.isLoading);
  const queryParam = new URLSearchParams(window.location.search);
  const [customHeight, setCustomHeight] = useState(0);

  const edgeId = queryParam.get("edgeId");

  useEffect(() => {
    console.log("Matrics Data: ", matricsData);
    console.log("Graph Data: ", graphData);
  }, [matricsData, graphData]);

  const adjustHeight = () => {
    let getMainContainer;
    let finalHeight;
    getMainContainer = document?.getElementsByClassName("gridContainer");
    finalHeight = getMainContainer?.[0]?.clientHeight - 45;
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    resizeend();
  }, []);

  const renderNeedle = (value, total, cx, cy, iR, oR, color) => {
    const RADIAN = Math.PI / 180;
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="none"
        fill={color}
      />,
    ];
  };

  const renderPieChart = (title, used, total, usedUnit, totalUnit) => {
    const usedValue = parseFloat(used);
    const totalValue = parseFloat(total);

    if (isNaN(usedValue) || isNaN(totalValue)) {
      return <NoDataComponent message={`No ${title} data available`} />;
    }

    const data = [
      { name: "Used", value: usedValue },
      { name: "Remaining", value: totalValue - usedValue },
    ];

    const COLORS = ["#00308f", "#D1EBFF"];
    const cx = 100;
    const cy = 100;
    const outerRadius = 80;
    const innerRadius = 50;
    return (
      <div className="piechart-item">
        <span className="chart-title" style={{ marginTop: "65px" }}>
          {title}
        </span>
        <PieChart width={200} height={110} style={{ scale: "0.7" }}>
          <Pie
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={180}
            endAngle={0}
            dataKey="value"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          {renderNeedle(
            usedValue,
            totalValue,
            cx,
            cy,
            innerRadius,
            outerRadius,
            General_color.warning
          )}
        </PieChart>
        <div className="details">
          <div>
            Used:{" "}
            {usedUnit.includes("%")
              ? ` ${usedUnit}`
              : ` ${usedValue} ${usedUnit}`}
          </div>
          <div>
            Total: {totalValue} {totalUnit}
          </div>
        </div>
      </div>
    );
  };
  const millicoresToCores = (millicores) => (millicores / 1000).toFixed(1);
  function bytesToSize(bytes, seperator = "") {
    if (bytes == 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(11)), 10);
    if (i === 0) return `${bytes}`;
    return `${((bytes / 10 ** i) * 10).toFixed(2)}`;
  }

  return (
    <div
      className="App contentApp"
      id="contentContainer"
      style={{ height: "25vh" }}
    >
      <div className="Heading_container">
        <Heading style={{ fontSize: "20px" }} text="Edge Overview" />
        {matricsData_isLoading || graphData_isLoading ? (
          <SpinnerLoader />
        ) : null}
      </div>
      <div
        style={{
          height: customHeight,
          overflow: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="piechart-container">
          {!matricsData_isLoading && !graphData_isLoading ? (
            matricsData.length > 0 && graphData.length > 0 ? (
              <>
                {renderPieChart(
                  "CPU",
                  (millicoresToCores(matricsData?.[0]?.data?.[0].v * 1000) *
                    Math.round(matricsData?.[1]?.data?.[0].v)) /
                    100,
                  `${millicoresToCores(matricsData?.[0]?.data?.[0].v * 1000)}`,
                  `${Math.round(matricsData?.[1]?.data?.[0].v)}%`,
                  "Core(s)"
                )}
                {renderPieChart(
                  "Memory (RAM)",
                  `${(
                    (matricsData?.[3]?.data?.[0].v / 100) *
                    bytesToSize(matricsData?.[2]?.data?.[0].v)
                  ).toFixed(2)}`,
                  `${bytesToSize(matricsData?.[2]?.data?.[0].v)}`,
                  "GB",
                  "GB"
                )}
                {renderPieChart(
                  "Storage",
                  `${(
                    (Math.round(matricsData?.[5]?.data?.[0].v) / 100) *
                    bytesToSize(matricsData?.[4]?.data?.[0].v)
                  ).toFixed(2)}`,
                  `${bytesToSize(matricsData?.[4]?.data?.[0].v)}`,
                  "GB",
                  "GB"
                )}
              </>
            ) : (
              <NoDataComponent />
            )
          ) : (
            <SpinnerLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default EdgeOverview;
