import React, { useEffect, useState } from "react";
import { apiEndPoints } from "../constants/ApiEndPoints";
import Axios from "axios";
import WarningIcons from "./WarningIcons/WarningIcons";
import { GeneralConstants, General_color } from "../constants/GeneralConstants";
import { MdChevronRight, MdInfo } from "react-icons/md";
import SpinnerLoader from "./SpinnerLoader/SpinnerLoader";
import { useDispatch, useSelector } from "react-redux";
import { BiChevronRight } from "react-icons/bi";
import {
  countForSuccessInstallation,
  resetInactiveTimer,
  setApiReloader,
  setPageNotifications,
} from "../reduxStore/actions";
import {
  calculateTimeElapsed,
  renderCalculatedTime,
} from "../constants/commonFunction";

function TempComponent({ data }) {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(data.limit);
  let setCountForInstallation_InitialValue = useSelector(
    (state) => state.setCountForInstallation
  );
  let [currentStatus, setCurrentStatus] = useState(0);
  const [count, setCount] = useState(0);
  let progresConstant =
    limit == 3
      ? GeneralConstants.appProgresConstant
      : GeneralConstants.progresConstant;
  let edgeDetails = useSelector((state) => state.setEdgeDetails);
  const [isExpand, setIsExpand] = useState(false);
  let oldNotifications = useSelector((state) => state.setPageNotifications);
  const [failedMessage, setFailedMessage] = useState(null);
  let initialInactive = useSelector((state)=>state.resetInactivityTimer)
  const resetTimer = () => {

    dispatch(resetInactiveTimer({payload:initialInactive+1}))
  }
  useEffect(() => {
    if (currentStatus !== "") {
      if (limit == 3) {
        if (data.isEdge === "osUpdate" || data.isEdge === 'runtimeUpdate') {
          get_OS_Status();
        } else {
          getStatus();
        }
      } else {
        getDeploymentStatus();
      }
    }
  }, [count]);

  useEffect(() => {
    if (currentStatus == limit) {
      setStatusCompleted({ isError: false, errMsg: null });
    }
  }, [currentStatus]);

  const setStatusCompleted = (parameter) => {
    let findTheFinished = oldNotifications.newData.filter(
      (ele, i) => ele.data.modalKey === data.modalKey
    );
    findTheFinished[0].data.isComplete = true;
    if (parameter.isError == true) {
      findTheFinished[0].data.successMessage = parameter.errMsg;
      findTheFinished[0].data.icon = "failed";
      findTheFinished[0].text = parameter.errMsg;
    } else if (
      findTheFinished[0].data.successMessage ==
      `Basic Edge Settings applied successfully`
    ) {
      //Adding auto reload after successfull edge deployment progress bar
      window.location.reload();
    }
    oldNotifications.isNew = false;
    sessionStorage.setItem(
      "sessionNotification",
      JSON.stringify(oldNotifications)
    );
  };
  const get_OS_Status = async () => {
    let fetchOS_RuntimeupdateStatus;
    if(data.isEdge=='runtimeUpdate'){
      fetchOS_RuntimeupdateStatus = apiEndPoints.FETCH_RUNTIME_STATUS(data.edgeId, localStorage.getItem('correlationId'))
    }else{
      fetchOS_RuntimeupdateStatus = apiEndPoints.FETCH_OS_STATUS(data.edgeId);
    }
    let fetch_app_status = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchOS_RuntimeupdateStatus,
      
    };

    if (currentStatus == "") {
      setCurrentStatus(0);
    }
    resetTimer()
    Axios.request(fetch_app_status)
      .then((response) => {
        console.log(response.data.status.status);
        if (response.data.status.status === "completed") {
          setCurrentStatus(3);

          return;
        }

        if (response.data.status.status === "inprogress") {
          setTimeout(() => {
            setCount((prev) => prev + 1);
          }, 10000);
        }

        if (response.data.status.status === "failed") {
          setCurrentStatus(-1);
          setFailedMessage(response.data.status.errorMessage);
          setStatusCompleted({
            isError: true,
            errMsg: response.data.status.errorMessage,
          });
          return;
        }

        setCurrentStatus(2);
      })
      .catch((error) => {
        console.log(error);
        setCurrentStatus(-1);
      });
  };

  const getStatus = async () => {
    let fetch_edge_setting_status;
    let fetch_application_status;

    if (data.isEdge === true) {
      fetch_edge_setting_status =
        apiEndPoints.FETCH_EDGE_SETTING_STATUS.replace("*edgeId*", data.edgeId);
      fetch_edge_setting_status = fetch_edge_setting_status.replace(
        "*parentTypeId*",
        data.setting_type
      );
      fetch_edge_setting_status = fetch_edge_setting_status.replace(
        "*sectionName*",
        data.settingSectionName
      );
    } else if (data.isEdge === false) {
      fetch_application_status = apiEndPoints.FETCH_APPLICATION_STATUS.replace(
        "*edgeId*",
        data.edgeId
      );
      fetch_application_status = fetch_application_status.replace(
        "*typeId*",
        data.setting_type
      );
      fetch_application_status = fetch_application_status.replace(
        "*operationType*",
        data.operation_type
      );
    }

    let fetch_app_status = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        window.location.pathname == "/edgedetails"
          ? fetch_edge_setting_status
          : fetch_application_status,
      
    };

    if (currentStatus == "") {
      setCurrentStatus(0);
    }
    resetTimer()
    Axios.request(fetch_app_status)
      .then((response) => {
        if (response.data.status === "completed") {
          setCurrentStatus(3);
          if (data.operation_type == "delete") {
            window.location.reload();
          }
          console.log("this is the correct bloack for te stae update");
          dispatch(
            countForSuccessInstallation({
              payload: setCountForInstallation_InitialValue + 1,
            })
          );
          return;
        }

        if (response.data.status != "completed") {
          setTimeout(() => {
            setCount((prev) => prev + 1);
          }, 10000);
        }

        setCurrentStatus(2);
      })
      .catch((error) => {
        console.log(error);
        setCurrentStatus(-1);
      });
  };

  const getDeploymentStatus = () => {
    let getDeployedAppStatusURL = apiEndPoints.GET_EDGE_DEPLOY_STATUS.replace(
      "*edgeId*",
      data.edgeId
    );
    getDeployedAppStatusURL = getDeployedAppStatusURL.replace(
      "*correlationId*",
      localStorage.getItem("correlationId")
    );

    let updateEdgeURL = apiEndPoints.UPDATE_EDGE.replace(
      "*edgeId*",
      data.edgeId
    );
    updateEdgeURL = updateEdgeURL.replace(
      "*correlationId*",
      localStorage.getItem("correlationId")
    );

    let config = {
      method: data.isUpdate ? "post" : "get",
      maxBodyLength: Infinity,
      url: data.isUpdate ? updateEdgeURL : getDeployedAppStatusURL,
      
    };

    if (currentStatus == "") {
      setCurrentStatus(0);
    }
    resetTimer()
    Axios.request(config)
      .then((response) => {
        if (currentStatus == limit) {
          dispatch(setApiReloader({ payload: 0 }));
        }

        if (currentStatus != limit) {
          setTimeout(() => {
            setCount((prev) => prev + 1);
          }, 10000);
        }

        let keyss = Object.keys(response.data);
        if (keyss.length > 0) {
          let latestStatus = Math.max(...keyss);
          setCurrentStatus(latestStatus);

          if (latestStatus >= limit) {
            localStorage.removeItem("correlationId");
          }
        }
      })
      .catch((error) => {
        dispatch(setApiReloader({ payload: 0 }));
        setCurrentStatus(-1);
        console.log(error);
      });
  };

  const returnErrStatus = () => {
    if (data.isEdge === "osUpdate") {
      if (failedMessage !== null) {
        return failedMessage;
      } else {
        return "Failed";
      }
    } else {
      return "Edge deployment failed";
    }
  };

  const toggleShowHide = (id) => {
    document.getElementById(`button_${id}`).classList.toggle("rotate");
    setIsExpand(!isExpand);
  };

  const listPreviousStat = () => {
    let noti = [];
    for (let num = currentStatus - 1; num >= 0; num--) {
      noti.push(num);
    }
    return noti.map((num, key) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            padding: "5px 0px",
            fontFamily: "ABBVoice",
          }}
        >
          <span>
            <WarningIcons type="good" />
          </span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{`${progresConstant[num]}`}</span>
            <span style={{ color: "#b0b0b0" }}>{`completed`}</span>
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <div className="notification">
        <div>
          <div
            style={{
              borderBottom: `1px solid ${General_color.disable}`,
              marginBottom: "5px",
              padding: "5px 10px",
              display: "flex",
              gap: "10px",
            }}
          >
            {/* <span style={{ fontSize: "18px", fontFamily: "ABBVoice-medium" }}>
              {data.isEdge ? "Edge" : "App"}
            </span> */}
            <span style={{ fontSize: "15px", fontFamily: "ABBVoice" }}>
              {data.isEdge === true ? (
                data.edgeName
              ) : (
                <>
                  {data?.edgeName}{" "}
                  <MdChevronRight style={{ marginBottom: "-2px" }} />{" "}
                  {data?.appName }
                </>
              )}
            </span>
          </div>
        </div>
        <div style={{ padding: "5px 10px" }}>
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
            }}
          >
            {currentStatus >= limit && <WarningIcons type="good" />}

            {currentStatus <= limit - 1 && currentStatus != -1 && (
              <span
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MdInfo fontSize={"20px"} color="#3366FF" />{" "}
                <SpinnerLoader
                  style={{
                    position: "absolute",
                    left: "0px",
                    // top: "15px",
                    scale: "1.2",
                  }}
                />
              </span>
            )}

            {currentStatus == -1 && <WarningIcons type="failed" />}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: "15px", fontFamily: "ABBVoice-medium" }}>
                {currentStatus == -1
                  ? returnErrStatus()
                  : currentStatus == limit
                  ? data.successMessage == "undefined" ||
                    data.successMessage == undefined ||
                    data.successMessage == null
                    ? `${progresConstant[currentStatus]}`
                    : data.successMessage
                  : `${progresConstant[currentStatus]}`}
              </span>
              <span>{renderCalculatedTime(data.time)}</span>
            </div>
          </div>
          {currentStatus != -1 && (
            <>
              {currentStatus <= limit - 1 && (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {
                    <WarningIcons
                      type="progressBar"
                      value={currentStatus}
                      limit={limit}
                    />
                  }
                  <span
                    style={{ fontFamily: "ABBVoice-medium", marginLeft: "5px" }}
                  >
                    {`${currentStatus}/${limit}`}
                  </span>
                </span>
              )}

              {currentStatus > 0 && (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    marginTop: "10px",
                    fontFamily: "ABBVoice",
                  }}
                  onClick={() => toggleShowHide(data.time)}
                >
                  <BiChevronRight
                    fontSize={20}
                    style={{ cursor: "pointer" }}
                    className=""
                    id={`button_${data.time}`}
                  />{" "}
                  {!isExpand ? "More" : "Less"}
                </span>
              )}

              {!isExpand ? <></> : <div>{listPreviousStat()}</div>}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TempComponent;
