import React, { useEffect, useState } from "react";
import Sidebar from "../components/SideBar/Sidebar";
import ResourceMonitorGraphs from "./pageComponents/ResourcesMonitorGraphs";
import { Grid, Box } from "grommet/components";

import axios from "axios";
import { apiEndPoints } from "../constants/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import {
  resetInactiveTimer,
  setAvailableApps,
  setEdgeAppDetails,
  setGraph,
  setInstalledApps,
  setInventory,
  setMetrics,
  setResourceMonitorGraph,
} from "../reduxStore/actions";
import { useParams } from "react-router-dom";
import { Col, Row, Select } from "antd";
import Podstatus from "../components/Podstatus/Podstatus";
import ResourceMonitorApplications from "./pageComponents/ResourceMonitorApplications";
import PodsSummary from "../components/PodsSummary/PodsSummary";
import ResourceMonitorPodList from "./pageComponents/ResourceMonitorPodList";
import NoDataComponent from "../components/NoDataComponent/NoDataComponent";
import { useHistory } from "react-router-dom";
import EdgeOverview from "../components/EdgeOverview/EdgeOverview";

function EdgeResourceMonitor() {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const apiReloader = useSelector((state) => state.setApiReloader);
  let initialInactive = useSelector((state)=>state.resetInactivityTimer)
  const resetTimer = () => {

    dispatch(resetInactiveTimer({payload:initialInactive+1}))
  }
  useEffect(() => {
    
    let fetchEdgeDetails = apiEndPoints.FETCH_SINGLE_EDGE_DETAILS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchEdgeDetails,
    };
    resetTimer()
    axios.request(config)
      .then((response) => {
        
         localStorage.setItem("edgeName",response?.data?.edgeDetails?.[0]?.name)
        
        dispatch(
          setEdgeAppDetails({
            payload: {
              data: response.data,
              isLoading: false,
            },
          })
        );
       
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    // Fetch content Metrics

    let Fetch_Metrics = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_METRICS,

      data: JSON.stringify({
        metrics: [
          "CPU_COUNT",
          "CPU_USAGE",
          "RAM",
          "RAM_USED",
          "DISK_SIZE",
          "DISK_USED",
        ],
        filters: [
          {
            name: "edge_uid",
            value: edgeId,
          },
        ],
      }),
    };
    resetTimer()
    axios
      .request(Fetch_Metrics)
      .then((response) => {
        dispatch(
          setMetrics({ payload: { data: response.data, isLoading: false } })
        );
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch Graph

    let Fetch_Graph = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_GRAPH,

      data: JSON.stringify({
        metrics: ["CPU_USAGE", "RAM_USED", "DISK_USED"],
        filters: [
          {
            name: "edge_uid",
            value: edgeId,
          },
        ],
      }),
    };
    resetTimer()
    axios
      .request(Fetch_Graph)
      .then((response) => {
        dispatch(
          setGraph({ payload: { data: response.data, isLoading: false } })
        );
        console.log("Range Metrics API response:- ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [apiReloader]);
  useEffect(() => {
    if (!edgeId) {
      history.push("/");
    }
  }, [edgeId, history]);
  let curr_App_reloadValue = useSelector(
    (state) => state.setInstallAppsReloader
  );
  let graphType = useSelector(
    (state) => state.setResourceMonitorGraph.graphType
  );
  const [apiLoaded, setApiLoaded] = useState(false);

  let [installedAppLoaded, setInstallAppLoaded] = useState(false);
  const [displayApplications, setDisplayApplications] = useState(true);
  const handleDisplayChange = (val) => {
    dispatch(
      setResourceMonitorGraph({
        payload: { graphType: "", data: {}, isLoading: true },
      })
    );
    setDisplayApplications(val === "installedApplications");
  };

  useEffect(() => {
    dataCollection();
  }, [curr_App_reloadValue, apiReloader]);
  // useEffect(() => {
  //   if (displayApplications) {
  //     dataCollection();
  //   }
  // }, [displayApplications]);

  const dataCollection = () => {
    setApiLoaded(false);
    setInstallAppLoaded(false);
    //List of Installed Applications
    let toGetInstalledApps = apiEndPoints.FETCH_INSTALLED_APPS.replace(
      "*edgeId*",
      edgeId
    );

    let Fetch_Installedapps = {
      method: "get",
      maxBodyLength: Infinity,
      url: toGetInstalledApps,
    };
    resetTimer()
    axios
      .request(Fetch_Installedapps)
      .then((response) => {
        dispatch(setInstalledApps({ payload: response.data }));
        if (response.status == 200) {
          setInstallAppLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (installedAppLoaded) {
      setApiLoaded(true);
    }
  }, [installedAppLoaded]);
  return (
    <section className="row-flex">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer">
        <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
          <Col
            span={24}
            className="gridContainer"
            style={{
              columnGap: "10px",
              display: "grid",
              rowGap: "10px",
              height: "20vh",
            }}
          >
            <EdgeOverview />
          </Col>
          <Col
            flex="0 1 100%"
            sm={24}
            md={24}
            lg={24}
            xl={6}
            style={{ maxWidth: "30%" }}
          >
            <Row>
              <Col
                className="gridContainer"
                style={{
                  borderRadius: "10px",
                  height: "70vh",
                  width: "92vw",
                  background: "#f5f5f5",
                }}
              >
                <Select
                  defaultValue="installedApplications"
                  style={{
                    width: "100%",
                    padding: "3px",
                    height: "40px",
                    fontFamily: "ABBVoice",
                    fontSize: "20px",
                  }}
                  className="resourceSelectorDropDown"
                  // onChange={handleDisplayChange}
                  onChange={(val) => handleDisplayChange(val)}
                  options={[
                    {
                      value: "installedApplications",
                      label: "Applications",
                    },
                    {
                      value: "edgesCore",
                      label: "Platform",
                    },
                  ]}
                />

                <div>
                  {displayApplications ? (
                    <ResourceMonitorApplications apiLoaded={apiLoaded} />
                  ) : (
                    <ResourceMonitorPodList />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            flex="1 1 525px"
            className="gridContainer"
            style={{
              columnGap: "10px",
              display: "grid",
              rowGap: "10px",
              height: "70vh",
            }}
          >
            {graphType == "applications" || graphType == "pod" ? (
              <ResourceMonitorGraphs />
            ) : (
              <NoDataComponent
                message={`Please select any of the ${
                  displayApplications ? "applications" : "platform services"
                } to display the resource data`}
              />
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default EdgeResourceMonitor;
