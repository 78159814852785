import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AreaChart, Area, ResponsiveContainer, Tooltip } from "recharts";
import { apiEndPoints } from "../constants/ApiEndPoints";
import { message } from "antd";
import NoDataComponent from "./NoDataComponent/NoDataComponent";
import { resetInactiveTimer } from "../reduxStore/actions";

function DummyAreaChart({ name, onFirstTimestampChange }) {
  const queryParam = new URLSearchParams(window.location.search);
  let dispatch = useDispatch()
  const edgeId = queryParam.get("edgeId");
  let [CPUValues, setCPUMetrics] = useState([]);
  let [RAMValues, setRAMMetrics] = useState([]);
  const [noMetricData, setNoMetricData] = useState(false);
  let initialInactive = useSelector((state)=>state.resetInactivityTimer)
  const resetTimer = () => {

    dispatch(resetInactiveTimer({payload:initialInactive+1}))
  }
  const fetchData = async () => {
    try {

      resetTimer()
      const response = await axios.post(
        apiEndPoints.FETCH_RESOURCE_LIMIT_GRAPHS,
        {
          edgeId: edgeId,
          modules: [name],
        }
      );
      setNoMetricData(false);
      if (response.data.metrics == null) {
        setNoMetricData(true);
      }
      const cpuMetrics = response?.data?.metrics[0];
      const ramMetrics = response?.data?.metrics[1];

      const convertedCPUMetrics = {
        ...cpuMetrics,
        data: cpuMetrics?.data?.map((item) => ({
          t: item?.t,
          v: parseFloat(item?.v),
        })),
        average: parseFloat(cpuMetrics?.average).toFixed(2),
        peak: parseFloat(cpuMetrics?.peak).toFixed(2),
        current: parseFloat(cpuMetrics?.current).toFixed(2),
      };

      const convertedRAMMetrics = {
        ...ramMetrics,
        data: ramMetrics?.data?.map((item) => ({
          t: item?.t,
          v: parseFloat(item?.v),
        })),
        average: parseFloat(ramMetrics?.average).toFixed(2),
        peak: parseFloat(ramMetrics?.peak).toFixed(2),
        current: parseFloat(ramMetrics?.current).toFixed(2),
      };

      setCPUMetrics(convertedCPUMetrics);
      setRAMMetrics(convertedRAMMetrics);
      if (cpuMetrics?.data?.length > 0) {
        onFirstTimestampChange(cpuMetrics.data[0].t);
      } else if (ramMetrics?.data?.length > 0) {
        onFirstTimestampChange(ramMetrics.data[0].t);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNoMetricData(true);
    }
  };

  useEffect(() => {
    if (name == undefined || name == null) return;
    setCPUMetrics([]);
    setRAMMetrics([]);
    fetchData();
  }, [name]);

  const CustomTooltip = ({ payload, label, active }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <span className="label">{`${payload?.[0]?.value}`}</span>
        </div>
      );
    }

    return null;
  };

  const currentCpu = CPUValues?.current;
  const averageCpu = CPUValues?.average;
  const peakCpu = CPUValues?.peak;
  const currentRam = RAMValues?.current;
  const averageRam = RAMValues?.average;
  const peakRam = RAMValues?.peak;
  if (noMetricData) {
    return (
      <div style={{ padding: "35px" }}>
        <NoDataComponent />
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
        className="dummyCharts"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100px",
              width: "100%",
            }}
          >
            <ResponsiveContainer height="100%">
              <AreaChart
                data={CPUValues?.data}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="v"
                  stroke="#00308F"
                  fill="#D1EBFF"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <span
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              fontFamily: "ABBVoice-bold",
              fontSize: "15px",
            }}
          >
            CPU (milliCores)
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>Current: {currentCpu}</span>
            <span>AVG: {averageCpu}</span>
            <span>Peak: {peakCpu}</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100px",
              width: "100%",
            }}
          >
            <ResponsiveContainer height="100%">
              <AreaChart
                data={RAMValues?.data}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="v"
                  stroke="#00308F"
                  fill="#D1EBFF"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <span
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              fontFamily: "ABBVoice-bold",
              fontSize: "15px",
            }}
          >
            RAM (MB)
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>Current: {currentRam}</span>
            <span>AVG: {averageRam}</span>
            <span>Peak: {peakRam}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default DummyAreaChart;
