import setData from "./SettingData";
import setGraph from "./setGraph";
import setEventLog from "./setEventLog";
import { combineReducers } from "redux";
import setEdges from "./setEdges";
import setAvailableApps from "./setAvailableApps";
import setEdgesMetrics from "./setEdgeMetrics";
import setInstalledApps from "./setInstalledApps";
import setBasicEdgeSettings from "./setBasicEdgeSettings";
import setBrokerEdgeSettings from "./setBrokerEdgeSettings";
import setRouterEdgeSettings from "./setRouterEdgeSettings";
import commonState from "../../Common/Reducer/ReducerCommon";
import showEdgeSettings from "./showEdgeSettings";
import confirmDialouge from "./confirmDialouge";
import confirmUpgradeDialouge from "./confirmUpgradeDialouge";
import deploymentProgess from "./deploymentProgess";
import setRoutesModels from "./setRoutesModels";
import setSummaryVisible from "./setSummaryVisible";
import setEdgeDetails from "./setEdgeDetails";
import setInventory from "./setInventory";
import setEdgeStatus from "./setEdgeStatus";
import setApiReloader from "./setApiReloader";
import setIsEdgeDeploying from "./setIsEdgeDeploying";
import isEdgeUpdateAvailable from "./isEdgeUpdateAvailable";
import setInstallAppsReloader from "./setInstallAppsReloader";
import setApplicationDetails from "./setApplicationDetails";
import setPageNotifications from "./setPageNotifications";
import setAuthenticationSettings from "./setAuthenticationSettings";
import setEdgeAdminSettings from "./setEdgeAdminSettings";
import setTempRoutes from "./setTempRoutes";
import isUnsavedEdgeSettings from "./isUnsavedEdgeSettings";
import setResourceMonitorGraph from "./setResourceMonitorGraph";
import setisAllDependenciesInstalled from "./setisAllDependenciesInstalled";
import setResourceLimitGraphData from "./setResourceLimitGraphData";
import setCountForInstallation from "./setCountForInstallation";
import setEdgeAppDetails from "./setEdgeAppDetails";
import resetInactivityTimer from "./resetInactiveTimer";

const rootReducer = combineReducers({
  setData,
  setGraph,
  setEventLog,
  setEdges,
  setAvailableApps,
  setEdgesMetrics,
  setInstalledApps,
  setBasicEdgeSettings,
  setBrokerEdgeSettings,
  setRouterEdgeSettings,
  commonState,
  showEdgeSettings,
  confirmDialouge,
  deploymentProgess,
  setRoutesModels,
  setSummaryVisible,
  setEdgeDetails,
  setInventory,
  setEdgeStatus,
  setApiReloader,
  setIsEdgeDeploying,
  isEdgeUpdateAvailable,
  setInstallAppsReloader,
  confirmUpgradeDialouge,
  setApplicationDetails,
  setPageNotifications,
  setAuthenticationSettings,
  setEdgeAdminSettings,
  setTempRoutes,
  isUnsavedEdgeSettings,
  setResourceMonitorGraph,
  setisAllDependenciesInstalled,
  setResourceLimitGraphData,
  setCountForInstallation,
  setEdgeAppDetails,
  resetInactivityTimer
});
export default rootReducer;
